<template>
    <div class="cube-container" v-if="imgs.length>0">
        <div class="cube initial-position">
            <img class="cube-face-image image-1" :src="imgs[0].resourceUrl">
            <img class="cube-face-image image-2" :src="imgs[1].resourceUrl">
            <img class="cube-face-image image-3" :src="imgs[2].resourceUrl">
            <img class="cube-face-image image-4" :src="imgs[3].resourceUrl">
            <img class="cube-face-image image-5" :src="imgs[4].resourceUrl">
            <img class="cube-face-image image-6" :src="imgs[5].resourceUrl">
        </div>
    </div>

    <div class="image-buttons" v-if="imgs.length>0">
        <div :class="active==0?'img_focus':''" ><img class="show-image-1" :src="imgs[0].resourceUrl" @click="changeImg($event,0)" /></div>
        <div :class="active==1?'img_focus':''" ><img class="show-image-2" :src="imgs[1].resourceUrl" @click="changeImg($event,1)" /></div>
        <div :class="active==2?'img_focus':''" ><img class="show-image-3" :src="imgs[2].resourceUrl" @click="changeImg($event,2)" /></div>
        <div :class="active==3?'img_focus':''" ><img class="show-image-4" :src="imgs[3].resourceUrl" @click="changeImg($event,3)" /></div>
        <div :class="active==4?'img_focus':''" ><img class="show-image-5" :src="imgs[4].resourceUrl" @click="changeImg($event,4)" /></div>
        <div :class="active==5?'img_focus':''" ><img class="show-image-6" :src="imgs[5].resourceUrl" @click="changeImg($event,5)" /></div>
    </div>
</template>

<script>
export default {
    props: {
        imgs: {
            type: Array,
            default: [],

        }
    },
    data () {
        return {
            active: 0,
        }
    },
    mounted () {
        window.addEventListener("DOMContentLoaded", function () {
            var cube = document.querySelector(".cube"),
                imageButtons = document.querySelector(".image-buttons");
            var cubeImageClass = cube.classList[1];
            imageButtons.addEventListener("click", function (e) {
                var targetNode = e.target.nodeName,
                    targetClass = e.target.className;
                if (targetNode === "IMG" && targetClass !== cubeImageClass) {
                    cube.classList.replace(cubeImageClass, targetClass);
                    cubeImageClass = targetClass;
                }
            });
        });
    },
    methods: {
        changeImg (e, idx) {
            this.active = idx
            var cube = document.querySelector(".cube")
            var cubeImageClass = cube.classList[1];
            var targetNode = e.target.nodeName,
                targetClass = e.target.className;
            if (targetNode === "IMG" && targetClass !== cubeImageClass) {
                cube.classList.replace(cubeImageClass, targetClass);
                cubeImageClass = targetClass;
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import './style.css';
</style>